import React, { useState,useRef,useEffect } from "react";
import "./CategoryFileList.css";
import FileIcon from "../../../../assets/svg/image-regular.svg";
import DocumentIcon from "../../../../assets/svg/file-icon.svg";
import DownArrow from "../../../../assets/svg/toggle-icon.svg";
import GroupRestriction from "../../../../assets/svg/group-icon.svg";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import AddSubCategory from "../../../../assets/svg/add-plus.svg";
import AddCategoryAndFile from './CategoryFileManager.js';
import FilePreview from "./FilePreview.js";
import PropTypes from 'prop-types';
import { DragDropContext, Droppable,Draggable } from 'react-beautiful-dnd';
import { setDocCategory } from '../../../../redux/action/docCategoryAction.js';
import { useSelector, useDispatch } from 'react-redux';
import { setSnackData, setLoading } from '../../../../redux/action/userAction';
import * as API from '../../../../common/api/index.js';
import URL from "../../../../common/api/constantURL";
import Confirmation from "../../../../common/component/Confirmation.js"
import { validatePermission } from "../../../../common/functions/permissionFunctions.js";
import { setToggleState } from "./CategoryFileList.js";

const moveItem = (items, id, type = "categories") => {
  for (let i = 0; i < items?.length; i++) {
    if (items[i].id == id && type === "files" && items[i].isFile) {
      return items.splice(i, 1)[0];
    }
    
    if (items[i].id == id && type === "categories" && !items[i].isFile) {
      return items.splice(i, 1)[0];
    }
    
    if (items[i].categories?.length > 0) {
      const item = moveItem(items[i].categories, id, type);
      if (item) return item;
    }
    
    if (type === "files" && items[i].files?.length > 0) {
      const item = moveItem(items[i].files, id, type);
      if (item) return item;
    }
  }
  return null;
};

const insertItem = (items, parentId, item, index, type = 'categories') => {
  if (!parentId) {
    items.splice(index, 0, item);
    return true;
  }
  for (const currentItem of items) {
    if (currentItem.id == parentId) {

      currentItem[type] = currentItem[type] || [];
      currentItem[type].splice(index, 0, item);
      return true;
    }

    if (currentItem.categories?.length > 0) {
      const insertedInCategories = insertItem(currentItem.categories, parentId, item, index, type);
      if (insertedInCategories) return true;
    }

    if (type === "files" && currentItem.files?.length > 0) {
      const insertedInFiles = insertItem(currentItem.files, parentId, item, index, type);
      if (insertedInFiles) return true;
    }
  }

  return false;
};

const onDragEnd = (result, initialData, setInitialData, type) => {
  const { destination, draggableId } = result;
  let uniqueIds = type === "categories" ? "subCategories-" : "subfiles-";

  if (!destination) return;

  let newCategories = JSON.parse(JSON.stringify(initialData.categories));
  const draggedItem = moveItem(newCategories, draggableId, type);

  if (!draggedItem) return;

  const destParentId = destination.droppableId.startsWith(uniqueIds) ? destination.droppableId.split("-")[1] : null;
  draggedItem.parentId = destParentId;

  insertItem(newCategories, destParentId, draggedItem, destination.index, type);
  setInitialData({ uncategorizedFiles: initialData.uncategorizedFiles, categories: newCategories });
};

const Row = ({ item, level = 0, index, id, draggable = false}) => {
  const categoriesList = useSelector(state => state.docCategoryReducer.categoriesFiles);
  const userPermissions = useSelector(state => state.userReducer.userPermissions);
  let cat = categoriesList;
  const [initialData, setInitialData] = useState(categoriesList);
  const ref = useRef()
  const isCategory = !!item.categories;
  const [isOpen, setIsOpen] = useState(item.isOpen || false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isFilePreview, setIsFilePreview] = useState(false);
  const [showAddCategoryPopup, setShowAddCategoryPopup] = useState(false);
	const [showAddFilePopup, setShowAddFilePopup] = useState(false);
  const [showEditCategoryPopup, setShowEditCategoryPopup] = useState(false);
	const [showEditFilePopup, setShowEditFilePopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [editModalData, setEditModalData] = useState({});
  const [isChild, setIsChild] = useState(false);
  const dispatch = useDispatch();
  const [confirmation,setConfirmation] = useState(false);
	const [deleteMessage, setDeleteMessage] = useState("");
  const [fileData, setFileData] = useState({});
  let hasEditDocumentsAndImagesPermission = (validatePermission(userPermissions, "EDIT_DOCUMENTS_AND_IMAGES"));
  let hasDeleteDocumentsAndImagesPermission = (validatePermission(userPermissions, "DELETE_DOCUMENTS_AND_IMAGES"));
  let hasAddDocumentsAndImagesPermission = (validatePermission(userPermissions, "ADD_DOCUMENTS_AND_IMAGES"));
  const [toggle, setToggle] = useState([]);
   
  useEffect(()=>{
    dispatch(setDocCategory(initialData))
  }, [initialData])

  useEffect(() => {
    setIsOpen(item.isOpen || false);
  }, [item.isOpen]);

  useEffect(() => {
		const checkIfClickedOutside = e => {
			if (isPopupVisible && ref.current && !ref.current.contains(e.target)) {
				setIsPopupVisible(false)
			}
		}
		document.addEventListener("mousedown", checkIfClickedOutside)
		return () => {
			document.removeEventListener("mousedown", checkIfClickedOutside)
		}
	}, [isPopupVisible])

  const toggleChildren = () => {
    setIsOpen(!isOpen);
    item.isOpen = !isOpen;
  };

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const fetchCategory = async () => {
    let url = URL.categories + "?facilityId=" + localStorage.getItem("facilityId");
    if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
      url = URL.categories
    }
    dispatch(setLoading(true))
    let response = await API.getAPI(url);
    dispatch(setLoading(false));
    if(response.fetchStatus === "success"){
      if(response.result.status === "success"){
        let categoryList = response.result.result;
        categoryList.categories = setToggleState(categoryList.categories, toggle);
        dispatch(setDocCategory(categoryList));
      }else{
        let snackData = {
          showSnack:true,
          snackMessage:response.result.message,
          snackVariant:"error"
        }
        dispatch(setSnackData(snackData));
      }
    }else if(response.fetchStatus === "failure"){
      let snackData = {
        showSnack:true,
        snackMessage:response.result.message,
        snackVariant:"error"
      }
      dispatch(setSnackData(snackData));
    }
  }

  const showDeleteAction = (data) => {
    setToggle(categoriesList.categories);
		setConfirmation(true);
    let type = data.isFile ? "file" : "category";
    let message = data?.categories?.length > 0 || data?.files?.length > 0 ? `Deleting this category ${data.name} will also delete all its files and subcategories`: `Are you sure you want to delete ${type} ${data.name} ?`;
		setDeleteMessage(message);
	};

  const showEditAction = (data, isFile = false) => {
    setToggle(categoriesList.categories);
    isFile ? setShowEditFilePopup(true) : setShowEditCategoryPopup(true);
    setShowPopup(true);
    togglePopup();
    setEditModalData(data);
  }

  const deleteEvents = async () => {
		let data = {
			"id": item.id,
			"facilityId": localStorage.getItem("facilityId"),
		}
		let response = null;
    dispatch(setLoading(true));
    if (isCategory) {
      response = await API.deleteAPI(URL.categories, data)
    } else {
      data["mediaId"] = item.mediaId || null;
      response = await API.deleteAPI(URL.files, data)
    }
    dispatch(setLoading(false));
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "success"
				}
				setConfirmation(false)
				fetchCategory();
				dispatch(setSnackData(snackData))
			} else {
        let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
      }
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: response.result.message,
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
	}

  const fetchFile = async () => {
		let url = `${URL.media}?id=${item.mediaId}&facilityId=${localStorage.getItem("facilityId")}` ;
    let data = {};

    setIsFilePreview(true)
    dispatch(setLoading(true));
    setToggle(categoriesList.categories);
		let response =  await API.getAPI(url); 
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
        data["type"] = response.result.result.mediaType;
        data["file"] = response.result.result.file;
        data["name"] = item.name;
        setFileData(data);
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
        dispatch(setLoading(false));
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: response.result.message,
				snackVariant: "error"
			}
      dispatch(setLoading(false));
			dispatch(setSnackData(snackData))
		}
	}

  return (
    <>
      <Draggable draggableId={`${String(id)}`} key={id} index={index} >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{
              ...provided.draggableProps.style,
              border: snapshot.isDragging ? "1px solid black" : "none",
              backgroundColor: snapshot.isDragging ? "#ededec" : "white", 
              margin: "5px 0",
              borderRadius: "5px",
              boxShadow: snapshot.isDragging ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none", 
              placeContent: "center"
            }}
          >
            <div data-testid="row-element" className="row-documents" {...(draggable ? provided.dragHandleProps : {})}  >
              <div className="cell menu-category">
                {isCategory ? (
                  <div className="flex category-name" data-testid="category-name">
                    <div style={{ paddingLeft: `${level * 29}px` }}></div>
                    <button  onClick={toggleChildren} data-testid="toggle-btn">
                    <img
                      src={DownArrow}
                      alt="downArrow"
                      className={!isOpen ? "toggle-button" : "toggle-button rotate"}
                    />
                    </button>
                    
                    <div className="category-name-wrapper" data-testid="name">{item.name}</div>
                  </div>
                ) : (
                  <div className={!draggable ? "flex files-name" : "flex files-name restrict-pointer"}>
                    <div
                      style={{ paddingLeft: `${level * 29}px` }}
                      className={!isCategory && level === 0 ? "no-category-files" : ""}
                    />
                    <img
                      alt="fileType"
                      className={
                        item.mimetype == "application/pdf"
                          ? "icons-dimention height-adjust"
                          : "icons-dimention"
                      }
                      src={item.mimetype == "application/pdf" ? DocumentIcon : FileIcon}
                    />
                    <button onClick={()=>{fetchFile()}} data-testid="file-name"><div title={item.name} className="category-name-wrapper">{item.name}</div></button>
                  </div>
                )}
                {!isCategory && (
                  <div className={!draggable ? "file-edit-wrapper" : "file-edit-wrapper restrict-pointer" } data-testid="files-manage-icon" ref={ref}>
                    {(hasDeleteDocumentsAndImagesPermission || hasEditDocumentsAndImagesPermission) && <FaRegEdit
                      data-testid="file-edit-action-icon"
                      id="edit-action-icon"
                      style={{
                        color: "#77838F",
                        width: "35px",
                        height: "16px",
                        cursor: "pointer",
                        marginRight: "-6px",
                      }}
                      onClick={()=>{togglePopup()}}
                    />}
                    {isPopupVisible && (
                      <div className="files-edit-popup">
                        {hasEditDocumentsAndImagesPermission && <button onClick={()=>{showEditAction(item, true)}}>
                          <div className="files-edit-popup-option" data-testid="edit-icon" >
                            Edit
                          </div>
                        </button>}
                        <button onClick={()=>{togglePopup();fetchFile();}}>
                          <div className="files-edit-popup-option" data-testid="preview-icon" >
                            Preview
                          </div>
                        </button>
                       {hasDeleteDocumentsAndImagesPermission && <button onClick={()=>{togglePopup();showDeleteAction(item)}}>
                          <div className="files-edit-popup-option" data-testid="delete-icon">
                            Delete
                          </div>
                        </button>}
                      </div>
                    )}
                  </div>
                  
                )}

                {isCategory && level < 5 && hasAddDocumentsAndImagesPermission && <button title="Add sub category" style={{ pointerEvents: draggable ? "none" : "auto"}}onClick={()=>{showEditAction(item, false); setIsChild(true)}}><img alt="addSubCat" className="add-sub-category"src={AddSubCategory} ></img></button>}

                {(!item.isInherited && item.isGroupRestriction) && (
                  <img
                    alt="groupRestriction"
                    src={GroupRestriction}
                    className="group-restriction"
                  />
                )}

              </div>
              <div className="cell last-updated" data-testid="last-updated-time">{item.updatedAt}</div>
              <div className="cell created-by-container" title={item.createdBy}><div className="created-by">{item.createdBy}</div></div>
              <div className={!draggable ? "cell manage-category" : "cell manage-category restrict-pointer" }>
                {isCategory && (
                  <>
                    {hasEditDocumentsAndImagesPermission && <FaRegEdit
                      data-testid="edit-action-icon"
                      id="edit-action-icon"
                      title="Edit"
                      style={{
                        color: "#77838F",
                        width: "20px",
                        height: "25px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {showEditAction(item, false)}}
                    />}

                    {hasDeleteDocumentsAndImagesPermission && <RiDeleteBinLine
                      data-testid="delete-action-icon"
                      id="delete-action-icon"
                      title="Delete"
                      style={{
                        color: "#77838F",
                        marginLeft: "3px",
                        width: "20px",
                        height: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() => {showDeleteAction(item)}}
                    />}
                  </>
                )}
              </div>
            </div>
            {isCategory && isOpen && (

          <div className="children">
            <button title="Add new file" style={{display: hasAddDocumentsAndImagesPermission ? "block" : "none"}} className={!draggable ? "add-file-button" : "add-file-button restrict-pointer" }onClick={()=>{showEditAction(item, true); setIsChild(true);}}>
              <div className="add-file" style={{ paddingLeft: `${level * 29}px` }}>
                <img alt="uploadFilesPlus" src={AddSubCategory} />
                <img alt="uploadFiles" src={DocumentIcon} ></img>
                <div>Add New File</div>
              </div>
            </button>
                <DragDropContext onDragEnd={(result) => onDragEnd(result, cat, setInitialData, "files")}>
                  <Droppable droppableId={`subfiles-${item.id}`}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {(item.files || []).map((file, index) => (
                          <Row key={index} item={file} level={level + 1} id={file.id} index={index} draggable={draggable}/>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <DragDropContext onDragEnd={(result) => onDragEnd(result, cat, setInitialData, "categories")}>
                  <Droppable droppableId={`subCategories-${item.id}`}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {item.categories.map((file, index) => (
                          <Row key={index} item={file} level={level + 1} id={file.id} index={index} draggable={draggable}/>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
        </div>
      )}
      </div>
        )}
      </Draggable>
     	{
				showPopup && <AddCategoryAndFile
				mode={showEditCategoryPopup || showAddCategoryPopup ? "CATEGORY" : "FILE"}
				data={showAddCategoryPopup || showAddFilePopup ? {} : editModalData}
        isCategory={false}
        isChild={isChild}
				onCancel={() => {
					setShowPopup(false);
          setShowAddCategoryPopup(false);
          setShowAddFilePopup(false);
          setShowEditCategoryPopup(false);
          setShowEditFilePopup(false);
          setIsChild(false);
          fetchCategory();
			}}
				/>
			}
      {confirmation ?
				<Confirmation className="file-preview-model" deleteMessage={deleteMessage} successFunc={() => deleteEvents()} cancelFunc={() => setConfirmation(false)} />
			: ""}
      {
        isFilePreview && Object.keys(fileData).length !== 0 && <FilePreview
        data = {fileData}
        onCancel={(e) => {
          setIsFilePreview(false);
          fetchCategory();
          setFileData({})
        }}
        />
      }
    </>
  );
};

Row.propTypes = {
  item: PropTypes.shape({
    categories: PropTypes.array,
    uncategorizedFiles: PropTypes.array,
    name: PropTypes.string,
    mimetype: PropTypes.string,
    label: PropTypes.string,
    isGroupRestriction: PropTypes.bool,
    files: PropTypes.array,
    createdBy: PropTypes.string,
    updatedAt: PropTypes.string,
    isOpen:PropTypes.bool,
    id:PropTypes.number,
    mediaId:PropTypes.string,
    isInherited:PropTypes.bool
  }),
  level: PropTypes.number,
  index:PropTypes.number,
  id:PropTypes.number,
  draggable:PropTypes.bool,
};

const CategoryTable = (props) => {
  const userPermissions = useSelector(state => state.userReducer.userPermissions);
  const categoriesList = useSelector(state => state.docCategoryReducer.categoriesFiles);
  const [initialData, setInitialData] = useState(props.currentItems);

  const dispatch = useDispatch();
  const onUncategorizedDragEnd = (result) => {
    const { destination, draggableId } = result;
    if (!destination) return;
    let uncategorizedFiles = [...initialData.uncategorizedFiles]
    const draggedItem = moveItem(uncategorizedFiles, draggableId, "files");
    if (!draggedItem) return;
    const destParentId =  null;
    draggedItem.parentId = destParentId;
    insertItem(uncategorizedFiles, destParentId, draggedItem, destination.index );
    setInitialData({uncategorizedFiles: uncategorizedFiles, categories: initialData.categories});
  };

  useEffect(() => {   
      dispatch(setDocCategory(initialData));
  }, [initialData]);
  
  useEffect(() => {
      setInitialData(categoriesList);
  }, [categoriesList]);
  return (
    <div className="category-table">
      <div className="header">
        <div className="cell menu-category" data-testid="menu-category">Menu Category</div>
        <div className="cell last-updated" data-testid="last-updated">Last Updated</div>
        <div className="cell created-by" data-testid="created-by">Created By</div>
        {(validatePermission(userPermissions, "EDIT_DOCUMENTS_AND_IMAGES") || validatePermission(userPermissions, "DELETE_DOCUMENTS_AND_IMAGES")) && <div className="cell manage-category" data-testid="manage-category">Manage</div>}
      </div>

      {(props.currentItems?.uncategorizedFiles?.length > 0 || props.currentItems?.categories?.length > 0) ? 
      <>
      <DragDropContext onDragEnd={onUncategorizedDragEnd}>
        <Droppable droppableId="files" >
          {(provided) => (
          <div
            className="document-container"
            ref={provided.innerRef} {...provided.droppableProps}
          >
            {props.currentItems?.uncategorizedFiles?.map((item, i) => (
              <Row key={i} item={item} id={item.id} index={i} draggable = {props.draggable}/>
            ))}
            {provided.placeholder}
          </div>
        )}
        </Droppable>
      </DragDropContext>
      <DragDropContext onDragEnd={(result) => onDragEnd(result, initialData, setInitialData, "categories")} >
        <Droppable droppableId={`categories`}>
          {(provided) => (
            <div
            className="document-container"
            ref={provided.innerRef}
            {...provided.droppableProps}
            >
            {props.currentItems?.categories?.map((item, i) => (
              <Row key={i} item={item} id={item.id} index={i} type="categories" draggable = {props.draggable}/>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      </>
      :
      <div className="no-data" data-testid="no-data">No Data Available</div>
      }
    </div>
  );

};

CategoryTable.propTypes = {
  currentItems: PropTypes.array,
  draggable: PropTypes.bool,
};
export default CategoryTable;
